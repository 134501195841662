import React from "react"
import { Link } from "gatsby"
import customDate from "@utils/customDate"
import ScrollTopHeader from "../ScrollTopHeader/ScrollTopHeader"

const MainPosts = ({ mainPosts }) => {
  return (
    <section className="main-posts-container">
      <ScrollTopHeader color="black" />
      <h2>
        <Link to="/blog">Blog Posts</Link>
      </h2>
      <div className="main-posts">
        {mainPosts.map((post) => {
          return (
            <div className="card-post" key={post.node.title}>
              <h3>{post.node.title}</h3>
              <div>
                <p>{customDate(post.node.publishedAt)}</p>
                <Link to={`/blog/${post.node.slug.current}`}>
                  Read the Post
                </Link>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default MainPosts
